import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NotifierModule } from "angular-notifier";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
//import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';

import { PDFEditorComponent } from "../../common/src/components/pdfeditor/pdfeditor.component";
import { PDFInputComponent } from "../../common/src/components/pdfeditor/pdfinput/pdfinput.component";
import { PDFPreviewComponent } from "../../common/src/components/pdfpreview/pdfpreview.component";
import { LocalisationService } from "../../common/src/modules/localisation/localisation.service";
import { LocalisePipe } from "../../common/src/modules/localisation/localise.pipe";
import { Config } from "../../common/src/services/config.service";
import { Logger } from "../../common/src/utility/logger.class";
//
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { CountryPipe } from "./pipes/CountryPipe";
import { LanguagePipe } from "./pipes/LanguagePipe";
import { PrestationStatePipe } from "./pipes/PrestationStatePipe";
import { SortDateInterpretationPipe } from "./pipes/SortDateInterpretationPipe";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { SignupComponent } from "./routes/account/signup/signup.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { DashboardComponent } from "./routes/app/dashboard/dashboard.component";
import { FacturesComponent } from "./routes/app/factures/factures.component";
import { FactureDetailComponent } from "./routes/app/facture-detail/facture-detail.component";
import { CheckoutComponent } from "./routes/app/checkout/checkout.component";
// Environment
// Moi
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./routes/app/home/home.component";
import { PrestationEditorComponent } from "./routes/app/prestations/prestation/editor/prestationEditor.component";
import { PrestationComponent } from "./routes/app/prestations/prestation/index/prestation.component";
import { PrestationBaseComponent } from "./routes/app/prestations/prestation/prestationBase.component";
import { PrestationListComponent } from "./routes/app/prestations/prestations.component";
import { ProfileComponent } from "./routes/app/profile/profile.component";
import {
  NgbdSortableHeader,
  TraducteurServiceComponent,
} from "./routes/app/services/service/traducteurService.component";
import { TraducteurServiceListComponent } from "./routes/app/services/traducteurServiceList.component";
import { SubscriptionComponent } from "./routes/app/subscription/subscription.component";
import { SupportComponent } from "./routes/app/support/support.component";
import { TraducteurComponent } from "./routes/app/traducteur/traducteur.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { ProfileCheckComponent } from "./routes/verify/profile/profilecheck.component";
import { VerifyBaseComponent } from "./routes/verify/verifybase/verifybase.component";
import { GoogleGeo } from "./services/google.services";
import { HieroBDD } from "./services/hierobdd.service";
import { AppWaitComponent } from "../../common/src/utility/app-wait/app-wait.component";
import { AppChatComponent } from "./utility/components/chat-component/chat.component";
import { AppDebounceInputComponent } from "../../common/src/utility/forms/app-debounce-input/app-debounce-input.component";
import { AppFormDropdownComponent } from "../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component";
import { AppFormGeneralErrorComponent } from "../../common/src/utility/forms/app-form-general-error/app-form-generl-error.component";
import { AppFormInputComponent } from "../../common/src/utility/forms/app-form-input/app-form-input.component";
import { AppFormTypeaheadComponent } from "../../common/src/utility/forms/app-form-typeahead/app-form-typeahead.component";
import { AppSwitchComponent } from "../../common/src/utility/forms/app-switch/app-switch.component";
import { AppModalConfirmComponent } from "../../common/src/utility/modal/confirm-modal.component";
import { LanguageDropdownComponent } from "../../common/src/utility/components/language-dropdown-component/language-dropdown.component";
import { ResetPasswordComponent } from "./routes/account/reset-password/reset-password.component";
import { AppModalManualAddressComponent } from "./utility/components/modal-manual-address/modal-manual-address.component";
import { MaintenanceComponent } from "./routes/maintenance/maintenance.component";
import { SubscriptionTraductor } from "./services/subsciption.services";
import { NotSubscribeModalComponent } from "./routes/not_found/not-subscribe-modal/not-subscribe-modal.component";
import { InterpretationsComponent } from "./routes/app/interpretations/interpretations.component";

import { CalendarComponent } from "./routes/app/interpretations/calendar/calendar.component";

import { RoomsComponent } from "./routes/app/interpretations/rooms/rooms.component"; // a plugin
import { EventModalComponent } from "./routes/app/interpretations/calendar/event-modal/event-modal.component"; // a plugin
import { InterpretationService } from "./services/interpretation.services";
import { CreditCardComponent } from "./routes/app/credit-card/credit-card.component";
import { EmailComponent } from "./routes/app/emailVerify/email.component";
import { CardComponent } from "./routes/app/card/card.component";
import { LoginModalComponent } from "./routes/app/profile/login-modal/login-modal.component";
import { ProfilePictureService } from "./services/profile-picture.services";
import { FullCalendarModule } from "@fullcalendar/angular";
import { InterpretationComponent } from "./routes/app/interpretations/interpretation/interpretation.component";

// Set logging level
Logger.LogLevel = environment.logLevel;

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    BaseComponent,

    // Account
    AccountComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,

    // Verify
    VerifyBaseComponent,
    ProfileCheckComponent,

    //Moi
    HomeComponent,
    SupportComponent,
    SubscriptionComponent,
    DashboardComponent,
    FacturesComponent,
    FactureDetailComponent,
    CheckoutComponent,
    CreditCardComponent,
    EmailComponent,
    //

    // App (logged in)
    AppBaseComponent,
    ProfileComponent,
    TraducteurComponent,
    TraducteurServiceListComponent,
    PrestationListComponent,
    PrestationBaseComponent,
    PrestationComponent,
    PrestationEditorComponent,

    // Components
    AppWaitComponent,
    AppFormInputComponent,
    AppFormGeneralErrorComponent,
    AppFormDropdownComponent,
    AppFormTypeaheadComponent,
    AppSwitchComponent,
    AppDebounceInputComponent,
    NgbdSortableHeader,
    AppModalConfirmComponent,
    AppModalManualAddressComponent,
    AppChatComponent,
    LanguageDropdownComponent,

    PDFPreviewComponent,
    PDFEditorComponent,
    PDFInputComponent,

    TraducteurServiceComponent,

    // Pipes
    LanguagePipe,
    CountryPipe,
    PrestationStatePipe,
    LocalisePipe,
    SortDateInterpretationPipe,
    MaintenanceComponent,
    CardComponent,
    NotSubscribeModalComponent,
    InterpretationsComponent,
    CalendarComponent,
    RoomsComponent,
    EventModalComponent,
    LoginModalComponent,
    InterpretationComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NotifierModule,
    NgxFileDropModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    FullCalendarModule,
  ],
  providers: [
    { provide: "CONFIG", useValue: environment },
    Config,
    HieroBDD,
    GoogleGeo,
    LocalisationService,
    LoggedInGuard,
    NgbActiveModal,
    SubscriptionTraductor,
    InterpretationService,
    ProfilePictureService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppModalConfirmComponent,
    AppModalManualAddressComponent,
    NotSubscribeModalComponent,
    LoginModalComponent,
  ],
})
export class AppModule {}
