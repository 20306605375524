import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ITraducteur } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import { HieroBDD } from "../../../../services/hierobdd.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import tippy from "tippy.js";
import { Router, ActivatedRoute } from "@angular/router";

export interface Ievent {
  id: string;
  title: string;
  start?: string;
  end?: string;
  allDay?: boolean;
  color?: string;
}
interface Iinterpreter {
  traducteur: ITraducteur;
  isInterpreter: boolean;
  availability: string[];
}

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  private traducteurId: string = undefined;
  private traducteurData: ITraducteur = undefined;

  public calendarPlugins = [dayGridPlugin, timeGridPlugin]; // important!
  public calendarEvents: Ievent[] = [];

  constructor(
    private hiero: HieroBDD,
    private modal: NgbModal,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.getTraductor();
    this.getMissions();
  }

  /* addAvailable(mydate: string) {
    const param = this.modal.open(EventModalComponent);
    param.componentInstance.parentDate = mydate;
  } */

  async getTraductor() {
    console.log("Id", this.hiero.Auth.User.Id);
    const refTaductor = await this.hiero.DB.collection("traducteurs")
      .where("uid", "==", this.hiero.Auth.User.Id)
      .get();
    const trad = refTaductor.docs.map((doc) => {
      this.traducteurId = doc.id;
      this.traducteurData = doc.data() as ITraducteur;
      return this.traducteurData;
    });
  }

  public async getMissions() {
    await this.hiero.DB.collection("interpretations_commandes")
      .where("traducteur", "==", this.traducteurData.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          this.calendarEvents = this.calendarEvents.concat({
            id: _.id,
            title: _.data().missions.context,
            start:
              _.data().missions.date + "T" + _.data().missions.time + ":00",
            color: "#5f2454",
            allDay: false,
            end:
              _.data().missions.date +
              "T" +
              this.end(_.data().missions.time, _.data().missions.extHours) +
              ":00",
          });
        })
      );
    await this.hiero.DB.collection("particuliers_commandes")
      .where("traducteur", "==", this.traducteurData.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          console.log(_.data());

          this.calendarEvents = this.calendarEvents.concat({
            id: _.id,
            title: _.data().missions.context,
            start:
              _.data().missions.date + "T" + _.data().missions.time + ":00",
            end:
              _.data().missions.date +
              "T" +
              this.end(_.data().missions.time, _.data().missions.extHours) +
              ":00",
            color: "#2d4054",
            allDay: false,
          });
          return _.data();
        })
      );
  }

  end(startTime, endTime) {
    var times = [0, 0];
    var max = times.length;

    var a = (startTime || "").split(":");
    var b = (endTime || "").split(":");

    // normalize time values
    for (var i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
    }

    // store time values
    for (var i = 0; i < max; i++) {
      times[i] = a[i] + b[i];
    }

    var hours = times[0];
    var minutes = times[1];

    if (minutes >= 60) {
      var h = (minutes / 60) << 0;
      hours += h;
      minutes -= 60 * h;
    }

    return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
  }

  test(event) {
    this.router.navigate(["app", "interpretations", event.event.id]);
    console.log(event.event.id);
  }

  /* ngAfterViewInit() {
    tippy("#myButton", { content: "I'm a Tippy tooltip!" });
  } */
}
