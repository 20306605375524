import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { BehaviorSubject } from "rxjs";
import { EmptyInterpreter, Iinterpreter, ITraducteur } from "../../../common/src/bdd/interfaces/ITraducteur";
import { ICalendDateFormat } from "../routes/app/interpretations/calendar/event-modal/event-modal.component";

const INTERPRETATON_COLLECTION = "interpretations"

@Injectable() export class InterpretationService {

    private user = firebase.auth().currentUser
    private dataService = new BehaviorSubject<any>(null)
    observer =  this.dataService.asObservable()
    private interpreteur: Iinterpreter = EmptyInterpreter
    interpreteurs: Iinterpreter[] ;
    
    /**
     * This fonction must be call when intretrpeur is true
     * @param interDate 
     */
    public AddAvailability(interDate? : ICalendDateFormat) {
        if (this.interpreteurs == undefined) {
            firebase.firestore().collection('traducteurs').where('email' ,'==', this.user.email)
            .get()
            .then((snap) => {
                this.interpreteurs = snap.docs.map(data => {
                    this.interpreteur.traducteur = data.data() as ITraducteur
                    this.interpreteur.traducteurId = data.id
                    this.interpreteur.isInterpreter = true
                    this.interpreteur.availability = [interDate]
                    console.log('dans le service',this.interpreteur)
                    firebase.firestore().collection(INTERPRETATON_COLLECTION).add(this.interpreteur)
                    return this.interpreteur
                })
            })
        } else {
            firebase.firestore().collection(INTERPRETATON_COLLECTION).where('traducteurId', '==', this.interpreteur.traducteurId)
            .get()
            .then((snap) => {
                snap.docs.map(el => {
                    console.log('SNAPPPP',el.data())
                     let lengthTab = this.interpreteurs[0].availability.length
                        console.log('taille du tableau', lengthTab)
                        this.interpreteurs[0].availability[lengthTab] = interDate
                        console.log('inter',this.interpreteurs[0])
                        firebase.firestore().collection(INTERPRETATON_COLLECTION).doc(el.id).set(this.interpreteurs[0])

                } )
            })
      

        }
   
    } 

}