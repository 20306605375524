// APP FRONTEND
export const APP_DOMAIN = '';
export const APP_SUBPATH = '';

// FIREBASE

export const GOOGLE_GEOCODE_API_KEY = 'AIzaSyBOWNQ0VD1AvJuYZwvQUdohHhJA_DCEIEo';
export const STRIPE_PUBLIC_KEY = 'pk_test_TEMhvuhDomKQEXfUFhEXUbn500JaCe57S9';
//export const STRIPE_PUBLIC_KEY_PROD = 'pk_live_pM9jTbpyzwLSJJ4VXh1ZKz5900Ig5Uwh9L';

export const ZOOM_API_KEY = 'xTL_D2ytTJiHBVfbVSgx-w'
export const TWILIO_API_SID = 'AC5583f20a5111f61689d5db908696a5ed';
export const TWILIO_API_KEY = 'SK6ae5385151aeb072368b78497ec10760';

