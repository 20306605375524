import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs/Subscription";
import { ITraducteur } from "../../../../../common/src/bdd/interfaces/ITraducteur";
import { HieroBDD } from "../../../services/hierobdd.service";
import { RoomsComponent } from "./rooms/rooms.component";

interface IinterpretationMission {
  action: string;
  date: string;
  destLang: string;
  srcLang: string;
  roomName: string;
  time: string;
  price: string;
  status: string;
  pro: string;
}

const missionEmpty: IinterpretationMission = {
  action: "",
  date: "",
  destLang: "",
  price: "",
  pro: "",
  roomName: "",
  srcLang: "",
  status: "",
  time: "",
};

@Component({
  selector: "app-interpretations",
  templateUrl: "./interpretations.component.html",
  styleUrls: ["./interpretations.component.scss"],
})
export class InterpretationsComponent implements OnInit {
  formInter: FormGroup;
  profSubs: Subscription;
  profile: ITraducteur;
  missions = [];
  page = 1;
  pageSize = 5;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();
    this.profSubs = this.hiero.WatchTraducteurProfile({
      next: (profile: ITraducteur) => {
        if (profile) {
          this.profile = profile;
          this.getMissions();
        }
      },
    });
  }
  createForm() {
    this.formInter = this.fb.group({
      interpreter: [""],
      interpretationPrice: [""],
    });
  }
  onSubmitForm() {
    console.log(this.formInter.value.interpreter);
    this.profile.interpreter = this.formInter.value.interpreter;
    this.profile.interpretationPrice = this.formInter.value.interpretationPrice;
    console.log(this.profile);
    this.hiero.Traducteur.UpdateProfile(this.profile);
  }

  showCalend(select: number) {
    const cal = document.getElementById("calend");
    const noCal = document.getElementById("noCal");
    const showCal = document.getElementById("showCal");
    if (select == 0) {
      noCal.classList.remove("hidden");
      cal.classList.remove("hidden");
      showCal.classList.add("hidden");
    } else if (select == 1) {
      noCal.classList.add("hidden");
      cal.classList.add("hidden");
      showCal.classList.remove("hidden");
    }
  }

  public getMissions() {
    this.hiero.DB.collection("interpretations_commandes")
      .where("traducteur", "==", this.profile.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => (this.missions[this.missions.length++] = _.data()))
      );
    this.hiero.DB.collection("particuliers_commandes")
      .where("traducteur", "==", this.profile.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => (this.missions[this.missions.length++] = _.data()))
      );
  }

  getRoom() {
    this.modal.open(RoomsComponent);
  }

  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    console.log("page", this.page);
    console.log("pageSize", this.missions.length);
  }

  open(mission: any) {
    console.log("mission", mission);
    //this.router.navigate(["app", "interpretations", mission.id]);
  }
}
