import { Component, OnInit, ElementRef } from '@angular/core';
import {HieroBDD} from '../../../services/hierobdd.service';
import {Chart} from 'chart.js'
import { array } from 'yup';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    commandeList: any;
    sales;
    rateSales;
    numberTrad;
    rateTrad;
    numberInterpretation;
    rateInter;
    newMission;
    rateNewMissions;
    dataChart1;
    totalTraduction;
    totalInterpretation;
    customers:any;
    LineChart=[];
    BarChart=[];
    DoughnutChart=[];

   

    constructor(private hiero: HieroBDD, private localisation: LocalisationService, private elementRef: ElementRef) {}

    async ngOnInit() {
        
        this.salesRevenues().then(number => this.sales =number);

        this.getNumberOftrad().then( number=> this.numberTrad = number);

        this.getNumberOfInterpretation().then(number=>this.numberInterpretation= number);

        this.getNumberOfNewMission().then(number => this.newMission = number);

        this.getMissionTrad().then( array => this.commandeList = array );
 
        let result = await this.servicesByLanguage()

        this.totalTrad().then(number=> this.totalTraduction = number);

        this.totalInter().then(number=> this.totalInterpretation = number);

        let evolTrad = await this.evolutionOftrad();

        let evolInter= await  this.evolutionOfInter();

        let donneeCirc= await this.labelDataCirc();

        this.rateMonthSales().then(number => this.rateSales=number);

        this.rateMonthNumberTrad().then(number => this.rateTrad = number);

        this.rateMonthNumberInter().then(number => this.rateInter = number);

        this.rateMonthNumberNewMissions().then(number=> this.rateNewMissions = number);

        this.clientAndTypeClients().then(array=> this.customers= array);

        
        
    setTimeout(() => {
        let htmlRef = this.elementRef.nativeElement.querySelector(`#lineChart`);
        this.LineChart = new Chart(htmlRef, {
            type: 'line',
            data: {
                labels: [
                    this.localisation.localise('dashboard_month_janv'),
                    this.localisation.localise('dashboard_month_fevr'),
                    this.localisation.localise('dashboard_month_mars'),
                    this.localisation.localise('dashboard_month_avr'),
                    this.localisation.localise('dashboard_month_mai'),
                    this.localisation.localise('dashboard_month_juin'),
                    this.localisation.localise('dashboard_month_juill'),
                    this.localisation.localise('dashboard_month_aout'),
                    this.localisation.localise('dashboard_month_sept'),
                    this.localisation.localise('dashboard_month_oct'),
                    this.localisation.localise('dashboard_month_nov'),
                    this.localisation.localise('dashboard_month_dec'),
                    ],
                datasets: result
            }, 
            options: {
                title:{
                    text: this.localisation.localise('dashboard_prestation_lang'),
                    display:true
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
    }, 1000);
       
        
        let htmlRef2 = this.elementRef.nativeElement.querySelector(`#barChart`);
        this.BarChart = new Chart(htmlRef2, {
            type: 'bar',
            data: {
                labels: [
                    this.localisation.localise('dashboard_month_janv'),
                    this.localisation.localise('dashboard_month_fevr'),
                    this.localisation.localise('dashboard_month_mars'),
                    this.localisation.localise('dashboard_month_avr'),
                    this.localisation.localise('dashboard_month_mai'),
                    this.localisation.localise('dashboard_month_juin'),
                    this.localisation.localise('dashboard_month_juill'),
                    this.localisation.localise('dashboard_month_aout'),
                    this.localisation.localise('dashboard_month_sept'),
                    this.localisation.localise('dashboard_month_oct'),
                    this.localisation.localise('dashboard_month_nov'),
                    this.localisation.localise('dashboard_month_dec'),
                    ],
                datasets: [{
                    label: this.localisation.localise('dashboard_trad'),
                    data: evolTrad,
                    backgroundColor: [
                        '  rgba(19, 19, 19, 0.3)',
                        '  rgba(19, 19, 19, 0.3)',
                        '  rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)',
                        '   rgba(19, 19, 19, 0.3)'
                        
                    ],
                    borderColor: [
                       
                        '  rgba(19, 19, 19, 1)',
                        '  rgba(19, 19, 19, 1)',
                        '  rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)',
                        '   rgba(19, 19, 19, 1)'

                        
                    ],
                    borderWidth: 0.5
                }, {
                    label: this.localisation.localise('dashboard_interpretation'),
                    data: evolInter,
                    backgroundColor: [
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)',
                        ' rgba(255, 91, 87, 0.3)'
                        
                    ],
                    borderColor: [
                            
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)',
                        ' rgba(255, 91, 87, 1)'
                      
                    ],
                    borderWidth: 0.5
                }]
            }, 
            options: {
                title:{
                    text: this.localisation.localise('dashboard_evolution_year'),
                    display:true
                },
                hover: {mode: null},
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });

        
        let htmlRef3 = this.elementRef.nativeElement.querySelector(`#doughnutChart`);
        this.DoughnutChart = new Chart(htmlRef3, {
            type: 'doughnut',
            data: {
                labels: donneeCirc[0],
                datasets: [{
                    label: '',
                    data: donneeCirc[1],
                    backgroundColor: [
                        'rgba(4, 139, 154, 0.2)',
                        'rgba(255, 173, 171, 0.2)',
                        'rgba(19, 19, 19, 0.2)'
                        
                    ],
                    borderColor: [
                        'rgba(4, 139, 154, 0.7)',
                        'rgba(255, 173, 171, 0.7)',
                        'rgba(19,19,19,0.7)'
                       
                    ],
                    borderWidth: 1
                }]
            }, 
            options: {
                title:{
                    text:"",
                    display:true
                },
               
            }
        });
    }



    // #################### Mes Missions en cours 


    getMissionTrad(){
        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              return missions.get().then(
                     
                    (trouve)=>{
                        let tab= trouve.docs
                        let tab2 = (tab.sort((a,b)=>{
                            return a.data().createdAt - b.data().createdAt
                        })).reverse();
                        
                        return tab2.map(doc => {
                            
                              return doc.data();
                            });
                    }

             )
          });
    
    }
// ######### Nombre de traduction ce mois



   async getNumberOftrad(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);

        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              return missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                    let incre=0

                    for (let i = 0; i < tab.length; i++) {
                        if (new Date(tab[i].data().lastModifiedAt).getMonth() === new Date().getMonth() && !(tab[i].data().acceptedByTranslatorAt===0)) {
                            incre= incre + 1
                        }
                        
                    }
                    
                    return incre
                   
                }

         )
          });

    }

    //  Nombre d'interpretation ce mois

    getNumberOfInterpretation(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);

        return docRef.get().then(
            (snapshot)=>{
                const trad = snapshot.docs[0].data().businessName;
                const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
                return interpretation.get().then(
                    (trouve)=>{
                        let tab= trouve.docs;
                        let incre=0

                        for (let i = 0; i < tab.length; i++) {
                            if (new Date(tab[i].data().missions.date).getMonth() === new Date().getMonth()) {
                                incre= incre + 1
                            }
                            
                        }
                        
                        return incre
                       
                    }
                )

            }
        )

    }

    // Mon chiffre d'affaires

      async  salesRevenues(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);


        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const trad = snapshot.docs[0].data().businessName;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
              let sum:number=0;
              return  missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                   
                    for (let i = 0; i< tab.length; i++) {

                        if (new Date(tab[i].data().lastModifiedAt).getMonth() === new Date().getMonth() && !(tab[i].data().acceptedByTranslatorAt===0) ) {
                            sum = sum + parseFloat(tab[i].data().price.traducteurHT);
                       
                        }
                       
                    }
                           return interpretation.get().then(
                               (trouve1)=>{
                                   let tab1= trouve1.docs
                                   for (let j = 0; j < tab1.length; j++) {

                                    if (new Date(tab1[j].data().lastModifiedAt).getMonth() === new Date().getMonth()) {
                                        sum+= parseFloat(tab1[j].data().price);
                                    }  
                                   }
                                //    console.log(sum);
                                   return sum
                               }
                           )
                }

         )
          });


    }

    //  Nouvelles missions ce mois

    async getNumberOfNewMission(){

        let trad = await this.getNumberOftrad();
        
        let inter = await this.getNumberOfInterpretation();


        let total= trad + inter;
       
 
        return total;
    }


    // Mes prestations par langues
 
    async servicesByLanguage(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
        let dataSet= [];
        let langag=[]
        let color = ["#FF5B57", "#048b9A", "#9a5204", "#ab8ab5"];

        return docRef.get().then(
            (snapshot)=>{
                const tradId = snapshot.docs[0].id;
                
                const tradServices = this.hiero.DB.collection('traducteur_services').where('traducteurId','==',tradId);
              
               
                return tradServices.get().then(
                    
                    (trouve)=>{
                        let tab= trouve.docs;
                     
                        for (let i = 0; i < tab.length; i++) {
                          
                          langag.push(tab[i].data().destLanguageIso639);
                         
                        }
                        
                        var uniqueArr = [...new Set(langag)]
                       
                        for (let l = 0; l < uniqueArr.length; l++) {
                            let donnee=[];

                            dataSet.push( {
                                label: uniqueArr[l],
                                data: [],
                                fill:false,
                                lineTension:0.2,
                                borderColor:color[l],
                                borderWidth: 1.5
                            });

                            const lang= this.hiero.DB.collection('prestations').where('traducteurId','==',tradId).where('destLanguageIso639','==',uniqueArr[l]);

                                lang.get().then(
                                    (trouve1)=>{
                                        let tab1= trouve1.docs

                                        let uniquetab1 = [...new Set(tab1)]
                                        
                                        for (let j = 0; j < 12; j++) {
                                            let incre=0
                                        
                                        for (let k = 0; k < uniquetab1.length; k++) {
                                          
                                            if (new Date(uniquetab1[k].data().lastModifiedAt).getMonth() === j && !(uniquetab1[k].data().acceptedByTranslatorAt===0) && j <= new Date().getMonth()) {
                                                incre = incre + 1
                                               
                                            }
                                            
                                        }
                                       
                                      
                                        donnee.push(incre);
                                    }
                                    
                                    dataSet[l].data=donnee;
                                 
                                    
                                    
                                    } 

                                )
                          
                        
                          
                            
                           
                           
                        }
                       
                        return dataSet
                        
                    }
                    
                )

            }
            
        )

    } 

 // ######## Total des traductions depuis l'inscription


    totalTrad() {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);


        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
          
              let sum=0
              return  missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                   
                    for (let i = 0; i< tab.length; i++) {

                        if (!(tab[i].data().acceptedByTranslatorAt===0)) {
                            sum += parseFloat(tab[i].data().price.traducteurHT);
                        }
                      
                    }

                    return sum
                          
                }

         )
          });

    }

    // ######## Total des interpretation depuis l'inscription


    totalInter() {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);

        return docRef.get().then(
            (snapshot) => {
           
              const trad = snapshot.docs[0].data().businessName;
           
              const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
              let sum=0
                    
              return interpretation.get().then(
                               (trouve1)=>{
                                   let tab1= trouve1.docs
                                   for (let j = 0; j < tab1.length; j++) {
                                       sum+= parseFloat(tab1[j].data().price);
                                       
                                   }
                                   return sum
                               }
                           )
                

         
          });

    }

    //  Ecolution du nombre de traduction sur l'année en cours


    async evolutionOftrad () {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
        let data = [];

        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              return missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                    let incre = 0
                   
                    for (let i = 0; i < 12; i++) {
                        incre=0
                       
                        for (let j = 0; j < tab.length; j++) {
                            
                            if (new Date(tab[j].data().lastModifiedAt).getMonth() === i && !(tab[j].data().acceptedByTranslatorAt===0)) {
                                incre = incre + 1;
                            }
                            
                        }
                        
                    data.push(incre)
                    }
                   
                    return data;
                }

         )
          });


    }

    // ############# Evolution du nombre d'interpretations sur l'année en cours

    async evolutionOfInter () {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
        let data = [];

        return docRef.get().then(
            (snapshot) => {
           
                const trad = snapshot.docs[0].data().businessName;
           
                const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);

              return interpretation.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                    let incre = 0
                   
                    for (let i = 0; i < 12; i++) {
                        incre=0
                       
                        for (let j = 0; j < tab.length; j++) {
                            
                            if (new Date(tab[j].data().missions.date).getMonth() === i) {
                                incre = incre + 1;
                            }
                            
                        }
                        
                    data.push(incre)
                    }
                   
                   return data;
                }

         )
          });

    }

    // ################################ Retourne l'ensemble des types de documents

    typeDocument() {
        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
   

        return docRef.get().then(

            (snapshot)=>{

                const traducteurId = snapshot.docs[0].id;
                const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);

                return missions.get().then(

                        (trouve)=> {
                            let type= []
                            let tab= trouve.docs;
                            
                            for (let i = 0; i < tab.length; i++) {
                                
                                if (!(tab[i].data().acceptedByTranslatorAt===0)) {
                                    type.push(tab[i].data().documents[0].documentTypeId)
                                }
                              
                                
                            }

                            return type.reduce(function (acc, curr) {
                                if (typeof acc[curr] === 'undefined') {
                                  acc[curr] = 1;
                                } else {
                                  acc[curr] += 1;
                                }
                              
                                return acc;
                              }, {});
                        }

                )

            }

        )
    }

    // ############################## Libelés du diagramme circulaire

    async labelDataCirc () {
            
            let value = await this.typeDocument()
            let cle = Object.entries(value)
            let valeur = (((Object.values(value)).sort(function(a:number, b:number) {
                return a - b;
              })).reverse()).slice(0,3)
         
            let tab=[]

            for (let i = 0; i < valeur.length; i++) {
            
                for (let j = 0; j < cle.length; j++) {
                    
                    if (cle[j][1]===valeur[i]) {
                        tab.push((cle[j][0]).substr(0,17))
                    }
                    
                }
                
            }
            let uniqueArr = [...new Set(tab)]
            return [uniqueArr.slice(0,3), valeur]


        
    }

    // Chiffre d'affaire du mois passé

      async  salesRevenuesLastMonth(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);


        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const trad = snapshot.docs[0].data().businessName;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
              let sum=0
              return  missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                   
                    for (let i = 0; i< tab.length; i++) {

                        if (new Date(tab[i].data().lastModifiedAt).getMonth() === new Date().getMonth() - 1  &&  !(tab[i].data().acceptedByTranslatorAt===0)) {
                            sum += parseFloat(tab[i].data().price.traducteurHT);
                        }
                       
                    }
                           return interpretation.get().then(
                               (trouve1)=>{
                                   let tab1= trouve1.docs
                                   for (let j = 0; j < tab1.length; j++) {

                                    if (new Date(tab1[j].data().lastModifiedAt).getMonth() === new Date().getMonth() - 1) {
                                        sum+= parseFloat(tab1[j].data().price);
                                    }  
                                   }
                                  
                                   return sum

                               }
                           )
                }

         )
          });

            
    }



    // Comparer les mois actuel et passé et déduire les taux d'évolutions du chiffre d'affaire

    async rateMonthSales(){

           let va:number = await this.salesRevenues()
           let vd:number  = await this.salesRevenuesLastMonth();

           if (!(vd===0)  ) {
            let rate = ((va - vd)/vd)*100
            
           
            return Math.round(rate * 100)/100 
           } else if(vd===0 && !(va===0)) {
             
               return 100
           }else {
               return 0
           }

           
    }

    // Traduction du mois passé

    async getNumberOftradLastMonth(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);

        return docRef.get().then(
            (snapshot) => {
           
              const traducteurId = snapshot.docs[0].id;
              const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
              return missions.get().then(
                     
                (trouve)=>{
                    let tab= trouve.docs;
                    let incre=0

                    for (let i = 0; i < tab.length; i++) {
                        if (new Date(tab[i].data().lastModifiedAt).getMonth() === new Date().getMonth() - 1  && !(tab[i].data().acceptedByTranslatorAt===0)) {
                            incre= incre + 1
                        }
                        
                    }
                    
                    return incre 
                   
                }

         )
          });

    }


// Comparer les mois actuel et passé et déduire les taux d'évolutions du nombre de traduction

        
        async rateMonthNumberTrad(){
        
            let va:number = await this.getNumberOftrad()
            let vd:number  = await this.getNumberOftradLastMonth();
        
            if (!(vd===0) ) {
             let rate = ((va - vd)/vd)*100
             
            
             return Math.round(rate * 100)/100 
            } else if(vd===0 && !(va===0)) {
              
                return 100
            }else {
                return 0
            }
        
            
        }


        // Interpretations du mois passé

    async getNumberOfInterLastMonth(){

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);

        return docRef.get().then(
            (snapshot)=>{
                const trad = snapshot.docs[0].data().businessName;
                const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
                return interpretation.get().then(
                    (trouve)=>{
                        let tab= trouve.docs;
                        let incre=0

                        for (let i = 0; i < tab.length; i++) {
                            if (new Date(tab[i].data().missions.date).getMonth() === new Date().getMonth() - 1) {
                                incre= incre + 1
                            }
                            
                        }
                        
                        return incre
                       
                    }
                )

            }
        )

    }

    // Comparer les mois actuel et passé et déduire les taux d'évolutions du nombre de traduction

    async rateMonthNumberInter(){
        
        let va:number = await this.getNumberOfInterpretation();
        let vd:number  = await this.getNumberOfInterLastMonth();
    
        if (!(vd===0)  ) {
         let rate = ((va - vd)/vd)*100
         
        
         return Math.round(rate * 100)/100 
        } else if(vd===0 && !(va===0)) {
          
            return 100
        }else {
            return 0
        }
    
        
    }

     // Nouvelles missions du mois passé

     async getNumberOfNewMissionLastMonth(){

        let trad = await this.getNumberOftradLastMonth();
        
        let inter = await this.getNumberOfInterLastMonth();


        let total= trad + inter;
       
 
        return total;
    }




     // Comparer les mois actuel et passé et déduire les taux d'évolutions du nombre de nouvelles missions

     async rateMonthNumberNewMissions(){
        
        let va:number = await this.getNumberOfNewMission()
        let vd:number  = await this.getNumberOfNewMissionLastMonth();
    
        if (!(vd===0) ) {
         let rate = ((va - vd)/vd)*100
         
        
         return Math.round(rate * 100)/100 
        } else if(vd===0 && !(va===0)) {
          
            return 100
        }else {
            return 0
        }
    
        
    }



     // ################################ Retourne l'ensemble des clients


   async  recurringCustomersData() {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
                
        return docRef.get().then(

            (snapshot)=>{

                const traducteurId = snapshot.docs[0].id;
                const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
                const trad = snapshot.docs[0].data().businessName;
                const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);

                return missions.get().then(

                        (trouve)=> {
                            let type= [];
                            let tab= trouve.docs;

                                  const personne = this.hiero.DB.collection("users");

                                return  personne.get().then(snapshot => {

                                    for (let i = 0; i < tab.length; i++) {
                                  
                                    snapshot.forEach(doc => {
                                      if (doc.id=== tab[i].data().uid) {
                                        type.push( doc.data().profile.givenName + " " + doc.data().profile.familyName);  
                                      }
                                        
                                   
                                  
                                    });
                                }
                              
                                return interpretation.get().then(

                                    (trouve)=> {
                                       
                                        let tab= trouve.docs;
                                        let type2= [];
            
                                              const personne2 = this.hiero.DB.collection("users");
            
                                            return  personne2.get().then(snapshot => {
    
                                                for (let j = 0; j < tab.length; j++) {
                                              
                                                snapshot.forEach(doc => {
                                                    
                                                  if (doc.id=== tab[j].data().professionnel.uid) {
                                                    type2[j]= doc.data().profile.givenName + " " + doc.data().profile.familyName; 
                                                    
                                                      
                                                  }
                                                    
                                               
                                              
                                                });
    
                                             
    
                                            }
                                           
                                            let type3= type.concat(type2);
                                            return type3.reduce(function (acc, curr) {
                                                if (typeof acc[curr] === 'undefined') {
                                                  acc[curr] = 1;
                                                } else {
                                                  acc[curr] += 1;
                                                }
                                              
                                                return acc;
                                              }, {});
                                              
                                              });                               
                                    }
                                    
            
                            )
                                  });
                                 
                              
                              
                                
                            
                           
                           
                        
                        }

                )

            }

        )
        

     }

     // Clients réccurents

    async getClients() {

   let clients = await this.recurringCustomersData();

//    console.log(clients)

   let cle = Object.entries(clients)
   let valeur = (((Object.values(clients)).sort(function(a:number, b:number) {
       return a - b;
     })).reverse()).slice(0,3)

   let tab=[]

   for (let i = 0; i < valeur.length; i++) {
   
       for (let j = 0; j < cle.length; j++) {
           
           if (cle[j][1]===valeur[i]) {
               tab.push((cle[j][0]))
           }
           
       }
       
   }
   let uniqueArr = [...new Set(tab)]
  
   return uniqueArr.slice(0,3)

}

// id réccurents

async  recurringCustomersId() {

    const user = this.hiero.Auth.User;
    const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
            
    return docRef.get().then(

        (snapshot)=>{

            const traducteurId = snapshot.docs[0].id;
            const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
            const trad = snapshot.docs[0].data().businessName;
            const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);

            return missions.get().then(

                    (trouve)=> {
                        let type= [];
                        let tab= trouve.docs;

                              const personne = this.hiero.DB.collection("users");

                            return  personne.get().then(snapshot => {

                                for (let i = 0; i < tab.length; i++) {
                              
                                snapshot.forEach(doc => {
                                  if (doc.id=== tab[i].data().uid) {
                                    type.push( tab[i].data().uid );  
                                  }
                                    
                               
                              
                                });
                            }
                            return interpretation.get().then(

                                (trouve)=> {
                                   
                                    let tab= trouve.docs;
                                    let type2= [];
        
                                          const personne2 = this.hiero.DB.collection("users");
        
                                        return  personne2.get().then(snapshot => {

                                            for (let j = 0; j < tab.length; j++) {
                                          
                                            snapshot.forEach(doc => {
                                                
                                              if (doc.id=== tab[j].data().professionnel.uid) {
                                                type2[j]= tab[j].data().professionnel.uid; 
                                                
                                                  
                                              }
                                                
                                           
                                          
                                            });

                                         

                                        }
                                       
                                        let type3= type.concat(type2);
                                        return type3.reduce(function (acc, curr) {
                                            if (typeof acc[curr] === 'undefined') {
                                              acc[curr] = 1;
                                            } else {
                                              acc[curr] += 1;
                                            }
                                          
                                            return acc;
                                          }, {});
                                          
                                          });                               
                                }
                                
        
                        )
                              });
                             
                          
                          
                            
                        
                       
                       
                    
                    }

            )

        }

    )
    

 }


 async getId() {

    let clients = await this.recurringCustomersId();
 
    let cle = Object.entries(clients)
    let valeur = (((Object.values(clients)).sort(function(a:number, b:number) {
        return a - b;
      })).reverse()).slice(0,3)
 
    let tab=[]
 
    for (let i = 0; i < valeur.length; i++) {
    
        for (let j = 0; j < cle.length; j++) {
            
            if (cle[j][1]===valeur[i]) {
                tab.push((cle[j][0]))
            }
            
        }
        
    }
    let uniqueArr = [...new Set(tab)]

    return  uniqueArr.slice(0,3)
 
 }



    // Type de clients: pro ou particulier?

    async  recurringTypeClient() {

        const user = this.hiero.Auth.User;
        const docRef = this.hiero.DB.collection('traducteurs').where('uid', '==', user.Id);
                
        return docRef.get().then(
    
            (snapshot)=>{
    
                const traducteurId = snapshot.docs[0].id;
                const missions = this.hiero.DB.collection('prestations').where('traducteurId', '==', traducteurId);
                const trad = snapshot.docs[0].data().businessName;
                const interpretation = this.hiero.DB.collection('interpretations_commandes').where('traducteur','==',trad);
    
                return missions.get().then(
    
                        (trouve)=> {
                            let type= [];
                            let tab= trouve.docs;
    
                                  const personne = this.hiero.DB.collection("users");
    
                                return  personne.get().then(snapshot => {
    
                                    for (let i = 0; i < tab.length; i++) {
                                  
                                    snapshot.forEach( doc => {
                                      if (doc.id=== tab[i].data().uid) {
                                        type.push( doc.id +" " +  doc.data().roles[0].type );  
                                      }
                                        
                                   
                                  
                                    });
                                }
                                return interpretation.get().then(
    
                                    (trouve)=> {
                                       
                                        let tab= trouve.docs;
                                        let type2= [];
            
                                              const personne2 = this.hiero.DB.collection("users");
            
                                            return  personne2.get().then(snapshot => {
    
                                                for (let j = 0; j < tab.length; j++) {
                                              
                                                snapshot.forEach(doc => {
                                                    
                                                  if (doc.id=== tab[j].data().professionnel.uid) {
                                                    type2[j]= doc.id +" "+ doc.data().roles[0].type ; 
                                                    
                                                      
                                                  }
                                                    
                                               
                                              
                                                });
    
                                             
    
                                            }
                                           
                                            let type3= type.concat(type2);
                                            return type3.reduce(function (acc, curr) {
                                                if (typeof acc[curr] === 'undefined') {
                                                  acc[curr] = 1;
                                                } else {
                                                  acc[curr] += 1;
                                                }
                                              
                                                return acc;
                                              }, {});
                                              
                                              });                               
                                    }
                                    
            
                            )
                                  });
                                 
                              
                              
                                
                            
                           
                           
                        
                        }
    
                )
    
            }
    
        )
        
    
     }
    
    
     async getTypeClient() {
    
        let clients = await this.recurringTypeClient();
     
        let cle = Object.entries(clients)
        let valeur = (((Object.values(clients)).sort(function(a:number, b:number) {
            return a - b;
          })).reverse()).slice(0,3)
     
        let tab=[]
     
        for (let i = 0; i < valeur.length; i++) {
        
            for (let j = 0; j < cle.length; j++) {
                
                if (cle[j][1]===valeur[i]) {
                    tab.push((cle[j][0]))
                }
                
            }
            
        }
        let uniqueArr = [...new Set(tab)]
    
        return  uniqueArr.slice(0,3)
     
     }

    async clientAndTypeClients() {
         const clients = await this.getClients();
         const type = await this.getTypeClient();

         let fusion = [];


         for (let i = 0; i < clients.length; i++) {

            fusion.push({nom: clients[i], type: type[i]})

 
         }
        
       
         return fusion
     }
    
        


     // Suppprimer les graphique inutiles sur la lineChart


        
}






