import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'sortDateInterpretation'})
export class SortDateInterpretationPipe implements PipeTransform {
  transform(value: any[]): any[] {
      return value.sort((a, b) => {
        return <any>new Date(a.missions.date) - <any>new Date(b.missions.date);}
      );
  }
}
