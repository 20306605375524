export const franceRelance = [
  {
    ID: "NJEcN2mverTmTx9g13Zbm6meEJh1",
  },
  {
    ID: "Al7dO8wC38eXCEIXHQPCSPzGFnW2",
  },
  null,
  {
    ID: "aPd3QVAXI0Tx3OZjMXstvHAuudm2",
  },
  {
    ID: "KbMLqGDyGNRDNVuMIgoI1wyT9713",
  },
  {
    ID: "tZpXQ48F0wgFeIZ4ZjDeSEACtkw2",
  },
  {
    ID: "mQdqdDRc9ucuW56q10EbEfkEWdL2",
  },
  null,
  {
    ID: "sPL9xV0uKRQpCBHkV7tJhDwxpCz1",
  },
  {
    ID: "5VeYaXEPrMaG3OK5zPJ4oeL4CTJ3",
  },
  {
    ID: "bJj1d6zcTZVOqapj3YincoZgm1n1",
  },
  {
    ID: "CDVNEr2d0xe16OOCncGbMLardEb2",
  },
  {
    ID: "VPuDcdCYuGWUrjFTod5XpSBASFm2",
  },
  {
    ID: "GPrHLLKdIjhJ2XsrFkC1anZSN922",
  },
  {
    ID: "uuphwIom9xfbIC19r05LxHDI9ks2",
  },
  {
    ID: "nARwG4KMdkeWWQOrer8vys90CA23",
  },
  {
    ID: "SHhHaNvAUvf7U9sq2fCLKi354cJ3",
  },
  {
    ID: "xbhhN8Q7ildC7i78A5qVtzJ8zSy1",
  },
  {
    ID: "C2P6tonGoVZhoHjSs7JuePwTbc23",
  },
  {
    ID: "dJcTLwVQKGbZOGwpNLfvHik0n1B2",
  },
  {
    ID: "W6kS9UkQcfUjHxO28X59t3yCxwk2",
  },
  {
    ID: "yPW7drBcuVVcIOqdhWzzeZpWegz2",
  },
  {
    ID: "uYGl5fSoEKhJmMBoDG8LlseNWoN2",
  },
  {
    ID: "ePnG7pzi6mUCwQcasjVC3ulCXOD3",
  },
  {
    ID: "5jjNG86f8UhpMbLdTW0VebU4aX32",
  },
  {
    ID: "Sgvx1XYcjnYp87p49OtEXIVPG673",
  },
  {
    ID: "oxdH7v38CUSoMomF5WuzCd7Ralc2",
  },
  {
    ID: "ge70vu24ImcRpwjcVfW6sxZSdrH3",
  },
  {
    ID: "OrjnJDnXuWUsk81hhtvK8PS5te02",
  },
  {
    ID: "Sh6p0iCIGWZr3AiJwfuMRWMDX6J3",
  },
  {
    ID: "0CEs63tJTZQNnSHBDvxzcYCpzZh2",
  },
  {
    ID: "OFvkoV4RWyYUuWfPGmlqqbn2zOO2",
  },
  {
    ID: "/",
  },
  null,
  null,
  {
    ID: "WCZPVIFpqYdqBtB9JE7inLftx1M2",
  },
  {
    ID: "jKttvcC7wOepx7AnwzIZ4O7Yxui1",
  },
  {
    ID: "YoYsUQGPj7S6RXNu3mYUnpU98qd2",
  },
  {
    ID: "xY359k6J6fhy4SMHJGk6iPLRJHR2",
  },
  null,
  {
    ID: "1qVrDqKVUXWBQU3mOHRPSwscFd62",
  },
  {
    ID: "cxnFx6pDmpMVewTGGDxjsnjiU8C3",
  },
  {
    ID: "cEYO0opRFvNvs9gSCoZnkT0vCsM2",
  },
  null,
  {
    ID: "sOMYBq9K1CbYTT5OHRTBKK4UZfl1",
  },
  {
    ID: "KrmYdMxsdzRP0YOefGok8aMgmIg2",
  },
  {
    ID: "mG4sRUbxQqT8Br2TsnqVPgurDsB3",
  },
  {
    ID: "wBp8HsFjYNOq1HdIdQL2AF5GFTK2",
  },
  {
    ID: "MCmx0HBSPnW5uChMQxQFiTbE3yB2",
  },
  {
    ID: "xBnLSctyHNWgeUVTqS9vO1xPdWv2",
  },
  {
    ID: "dM3VmmN23tX0puaqU8mPpH8j7Gw1",
  },
  {
    ID: "XYgvQoXcoPOWmoZKTFc7GA5nXYV2",
  },
  {
    ID: "f59lf27OhAhmoyqJClywpM6LqJs1",
  },
  {
    ID: "EJQ7pXp36va5knNmyo0U2GM888R2",
  },
  {
    ID: "jyvoELk7XBYwGHFfvfbcyM2aAdx1",
  },
  {
    ID: "3W1fB4DmHTMxN3EfEujhCNEG4JV2",
  },
]
  .map((user) => (user !== null ? user.ID : null))
  .filter((user) => user !== null && user !== "/");
