import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as firebase from "firebase";
import * as jsPDF from "jspdf";
import { Language } from "../../../../../common/src/bdd/utility/Language";

import { HieroBDD } from "../../../services/hierobdd.service";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";

// for jspdf

let img = new Image();
img.src = "../assets/img/logo/fact.png";

var doc = new jsPDF();
var pageHeight =
  doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

@Component({
  selector: "app-factures",
  templateUrl: "./factures.component.html",
  styleUrls: ["./factures.component.scss"],
})
export class FacturesComponent implements OnInit {
  factureList = Array();
  factureList2 = Array();
  page = 1;
  pageAsser = 1;
  pageInter = 1;
  pageSize = 10;
  currentUser: any;
  client: any;
  clientPro: any;
  product: any;
  loadFullNameClient: any;
  private isPart: boolean = false;
  private isTVA: boolean = false;

  constructor(
    private hiero: HieroBDD,
    private localisation: LocalisationService
  ) {}

  @ViewChild("content") content: ElementRef;

  async ngOnInit() {
    this.factureList = await this.getAllFacture();

    setTimeout(() => {
      this.factureList2 = this.factureList.slice();
      this.factureList2.sort((x, y) => {
        return y.data.date.toDate() - x.data.date.toDate();
      });
    }, 1000);

    this.product = await this.theProduct();

    await this.getClientData();
  }

  // get the trad's Info
  async getTradInfo() {
    const user = this.hiero.Auth.User;
    const docRef = this.hiero.DB.collection("traducteurs").where(
      "uid",
      "==",
      user.Id
    );
    return docRef.get().then((snapshot) => {
      return snapshot.docs[0];
    });
  }

  async getAllFacture() {
    let tab = [];
    const tradInfo = await this.getTradInfo();
    const tradName = tradInfo.data().businessName;
    this.isTVA = tradInfo.data().assujettiTVA;
    const factures = this.hiero.DB.collection("facture").where(
      "traducteurName",
      "==",
      tradName
    );

    factures.get().then((trouve) => {
      return trouve.docs.map((data) => {
        tab.push({
          id: data.id,
          data: data.data(),
        });
        return tab;
      });
    });

    const factures_part = this.hiero.DB.collection(
      "facture_particuliers"
    ).where("traducteurName", "==", tradName);
    factures_part.get().then((trouve) => {
      return trouve.docs.map((data) => {
        tab.push({
          id: data.id,
          data: data.data(),
        });
        return tab;
      });
    });

    // tab.sort((x, y) => {
    //   return y.data.date - x.data.date;
    // });

    return tab;
  }

  async getClientData() {
    this.factureList.forEach((el) => {
      if (el.data.professionnelId) {
        const docRef = this.hiero.DB.collection("professionnels").doc(
          el.data.professionnelId
        );
        docRef.get().then((data) => {
          this.getFullNameClient(data.data().uid).then((data) => {
            this.loadFullNameClient = data;
          });
          this.client = data.data();
          return data.data();
        });
      }
      if (el.data.particulierId) {
        const docRef = this.hiero.DB.collection("particuliers").doc(
          el.data.particulierId
        );
        docRef.get().then((data) => {
          this.getFullNameClient(data.data().uid).then((data) => {
            this.loadFullNameClient = data;
          });
          this.client = data.data();
          return data.data();
        });
      }
    });
  }

  async getFullNameClient(uid: string) {
    const docRef = this.hiero.DB.collection("users").doc(uid);
    const data = await docRef.get();
    return data.data();
  }

  async theProduct() {
    let facture = await this.getAllFacture();
    let tab = [];

    setTimeout(() => {
      facture.forEach((data, index) => {
        if (data.data.room) {
          return tab.push(data.data.room);
        } else {
          if (data.data.professionnelId) {
            console.log("pro");

            this.isPart = false;

            const docRef = this.hiero.DB.collection("professionnel_commandes")
              .where("prestationId", "==", data.data.prestation)
              .get();
            return docRef.then((el) => {
              return tab.push(el[0].data().documentType);
            });
          }
          if (data.data.particulierId) {
            console.log("part");
            this.isPart = true;
            const docRef = this.hiero.DB.collection("particuliers_commandes")
              .where("prestationId", "==", data.data.prestation)
              .get();
            return docRef.then((el) => {
              return tab.push(el[0].data().documentType);
            });
          }
        }
      });

      return tab;
    }, 1000);
  }

  onPageChange(pageNumber: number, switchnmbr: number) {
    switch (switchnmbr) {
      case 0:
        this.pageAsser = pageNumber;
        // console.log('pageCOm n:', this.pageCom)

        break;
      case 1:
        this.page = pageNumber;
        // console.log('pagePre n:', this.pagePre)

        break;
      case 2:
        this.pageInter = pageNumber;
        // console.log('pageUser n:', this.pageUser)
        break;
      /*case 3:
        this.pageTrad = pageNumber
        //console.log('pageUser n:', this.pageTrad)
        break; */
      default:
        break;
    }
  }

  public async download(param, param2) {
    setTimeout(() => {
      var doc = new jsPDF();

      // ####### Adresse de Hiero
      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(this.localisation.localise("support_company"), 10, 10);

      doc.setFontSize(10);
      doc.setFontType("normal");
      doc.text(this.localisation.localise("support_addr1"), 10, 15);
      doc.text(this.localisation.localise("support_addr2"), 10, 20);
      doc.text(this.localisation.localise("support_tel"), 10, 25);
      doc.text(this.localisation.localise("support_email"), 10, 30);

      //##### Logo

      doc.addImage(img, "png", 150, 10, 50, 40);

      // ##### Titre facture
      doc.setTextColor("#00acac");
      doc.setFontSize(20);
      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("head_facture").toUpperCase(),
        10,
        70
      );
      doc.setFontType("normal");
      doc.setTextColor(255, 0, 0);
      doc.setFontSize(30);

      // Infos sous le titre de la facture:
      doc.setTextColor("black");
      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("facture_numero").toUpperCase(),
        20,
        80
      );
      doc.text(
        this.localisation.localise("section_date").toUpperCase(),
        20,
        85
      );
      doc.text(
        this.localisation.localise("facture_echeance").toUpperCase(),
        20,
        90
      );
      doc.text(
        this.localisation.localise("facture_conditions").toUpperCase(),
        20,
        95
      );

      doc.setFontType("normal");

      doc.text(`${this.factureList[param].data.numero}`, 50, 80);
      doc.text(
        new Date(
          this.factureList[param].data.date.seconds * 1000
        ).toLocaleDateString("fr-FR"),
        50,
        85
      );
      doc.text(
        new Date(
          this.factureList[param].data.date.seconds * 1000
        ).toLocaleDateString("fr-FR"),
        50,
        90
      );
      doc.text(this.localisation.localise("facture_comptant"), 50, 95);

      // ADRESSE DE FACTURATION

      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("facture_adress").toUpperCase(),
        130,
        80
      );
      doc.setFontType("normal");
      doc.text(
        this.loadFullNameClient.profile.familyName +
          " " +
          this.loadFullNameClient.profile.givenName,
        130,
        87
      );
      doc.text(this.localisation.localise("facture_pro"), 130, 92);
      doc.text(
        this.client.address.number + " " + this.client.address.street,
        130,
        97
      );
      doc.text(
        this.client.address.code + " " + this.client.address.city,
        130,
        102
      );
      doc.text(this.client.address.country, 130, 107);

      // Ligne horizontal
      doc.setDrawColor("#00acac");
      doc.line(pageWidth - 20, 120, 20, 120);
      // doc.line(190, 151, 30, 151);

      // ####### Le tableau

      // # En tête
      doc.setFillColor(0, 172, 172);

      doc.rect(20, 125, pageWidth - 40, 10, "F");

      doc.setTextColor("#FFFFFF");

      doc.text(
        this.localisation.localise("facture_qte").toUpperCase(),
        21,
        131
      );

      doc.text(
        this.localisation.localise("commande_produit").toUpperCase(),
        33,
        131
      );

      doc.text(
        this.localisation.localise("commande_desc").toUpperCase(),
        73,
        131
      );

      doc.text(
        this.localisation.localise("tasks_price_tva").toUpperCase(),
        127,
        131
      );

      doc.text(
        this.localisation.localise("facture_price").toUpperCase(),
        147,
        129
      );
      doc.text(
        this.localisation.localise("facture_unitaire").toUpperCase(),
        147,
        133
      );

      doc.text(
        this.localisation.localise("facture_montant").toUpperCase(),
        171,
        131
      );

      // # La data

      doc.setTextColor("black");

      doc.text(
        `${
          this.factureList[param].data.pagesNumber
            ? this.factureList[param].data.pagesNumber
            : 1
        }`,
        21,
        143
      ); //  QTE

      doc.text(this.product[param], 33, 143); //  Product
      let type: string = "";
      if (this.factureList[param].data.typePrestation === "Interprétation")
        type = this.localisation.localise("interpretation");
      if (
        this.factureList[param].data.typePrestation === "Traduction assermentée"
      )
        type = this.localisation.localise("traduction_assermentee");
      if (
        this.factureList[param].data.typePrestation === "Traduction classique"
      )
        type = this.localisation.localise("traduction_classic");
      doc.text(
        type +
          " " +
          this.localisation.localise("prestation_order_from").toLowerCase() +
          "\n " +
          Language.GetNameFromISO639(
            this.factureList[param].data.srcLang
          ).toLowerCase() +
          " " +
          this.localisation.localise("order_language_to").toLowerCase() +
          " " +
          Language.GetNameFromISO639(
            this.factureList[param].data.destLang
          ).toLowerCase(),
        73,
        143
      ); // Description (faire la gestion de l'espace direcetement avec substr() et \n)

      doc.text("20 %", 127, 143); // TVA

      doc.text(
        (
          this.factureList[param].data.priceHT.toFixed(2) /
          (this.factureList[param].data.pagesNumber
            ? this.factureList[param].data.pagesNumber
            : 1)
        ).toFixed(2),
        152,
        143
      ); // Question par rapport au Prix Unitaire

      doc.text(
        this.factureList[param].data.priceHT.toFixed(2),
        pageWidth - 30,
        143
      ); // Montant total

      doc.setDrawColor("#00acac");
      // doc.line(190, 185, 30, 185);
      doc.line(pageWidth - 20, 155, 20, 155);

      // ##### Table des totaux
      //# Col 1

      doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
      doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
      doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
      doc.text(
        this.localisation.localise("facute_solde").toUpperCase(),
        127,
        195
      );

      // # Col 2

      doc.text(
        this.factureList[param].data.priceHT.toFixed(2),
        pageWidth - 30,
        165
      );
      doc.text(
        this.factureList[param].data.priceTVAVal.toFixed(2),
        pageWidth - 30,
        175
      );
      doc.text(
        this.factureList[param].data.priceTTC.toFixed(2),
        pageWidth - 30,
        185
      );

      doc.setTextColor("#00acac");
      doc.setFontSize(15);
      doc.setFontType("bold");
      doc.text(
        this.factureList[param].data.priceTTC.toFixed(2) + " " + "EUR",
        165,
        195
      );

      doc.setFontType("normal");

      // Recap TVA
      doc.setFontSize(12);
      doc.setTextColor("black");
      doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
      doc.setFontSize(10);

      // doc.text('MONTANT DE TVA', )

      // doc.text('BASE HT', )

      // doc.text()

      doc.setTextColor("black");

      // Lignes inférieures

      // doc.setDrawColor('#00acac')
      // doc.line(30, 230, 190, 230)
      // doc.line(20, 201, pageWidth-20, 201)
      doc.setFillColor(0, 172, 172);

      doc.rect(20, 211, pageWidth - 40, 10, "F");

      doc.setTextColor("#FFFFFF");
      doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
      doc.text(
        this.localisation.localise("recap_mt_tva").toUpperCase(),
        90,
        217
      );
      doc.text(
        this.localisation.localise("recap_base_ht").toUpperCase(),
        170,
        217
      );

      doc.setTextColor("black");

      doc.text(
        this.localisation.localise("facture_tva_fr").toUpperCase(),
        25,
        227
      );
      doc.text(this.factureList[param].data.priceTVAVal.toFixed(2), 100, 227);
      doc.text(this.factureList[param].data.priceHT.toFixed(2), 179, 227);

      // Pénalité et etc...
      doc.setFontType("normal");
      doc.setFontSize(10);

      doc.setTextColor("black");
      doc.text(
        this.localisation.localise("condition_paragraphe_1"),
        pageWidth / 2,
        245,
        {
          align: "center",
        }
      );
      doc.text(
        this.localisation.localise("condition_paragraphe_2"),
        pageWidth / 2,
        250,
        {
          align: "center",
        }
      );
      doc.text(
        this.localisation.localise("condition_paragraphe_3"),
        pageWidth / 2,
        255,
        { align: "center" }
      );

      // Footer

      doc.setDrawColor("#00acac");
      doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
      doc.setFontSize(8);
      doc.text(
        this.localisation.localise("capital_sasu") +
          " - " +
          this.localisation.localise("agency_address_number") +
          " " +
          this.localisation.localise("rcs_infos") +
          " - " +
          this.localisation.localise("agency_address_number") +
          " " +
          this.localisation.localise("siret") +
          " - " +
          this.localisation.localise("agency_address_number") +
          " " +
          this.localisation.localise("tva_fr"),
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );

      if (param2 === 1) {
        doc.autoPrint();
        //This is a key for printing
        doc.output("dataurlnewwindow");
      } else {
        //Save the PDF
        doc.save("Facture.pdf");
      }
    }, 1000);
  }
}
