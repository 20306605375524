<div class="">
  <div class="row op" id="loadPageId">
    <!-- TODO: Add 'op' class and this div-->
    <div class="col">
      <h3>{{ "interpretation_label" | localise }}</h3>
      <!-- <ul class="list-group">
        <li class="list-group-item ">
          <label>date</label>
          <label>clients</label>
          <label>status</label>
          <button type="button" class="btn btn-style pull-right">Detail</button>
        </li>
      </ul> -->
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" class="align-middle">{{ "action" | localise }}</th>
            <th scope="col" class="align-middle">
              {{ "account_hist_pro_date" | localise }}
            </th>
            <th scope="col" class="align-middle">{{ "client" | localise }}</th>
            <th scope="col" class="align-middle">
              {{ "tasks_price" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_state" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_src_lang" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_dst_country" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "room_conf" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "room_inter" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "temps_ex" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "agency_setup_address" | localise }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let mission of missions
                | sortDateInterpretation: missions
                | slice: (page - 1) * pageSize:page * pageSize
            "
            class="clickable"
            (click)="open(mission)"
          >
            <td *ngIf="!mission.is_interpretation" class="align-middle">
              <button
                [disabled]="mission.missions.address != ''"
                class="btn btn-success"
                (click)="getRoom()"
              >
                {{ "join_room" | localise }}
              </button>
            </td>
            <td *ngIf="mission.is_interpretation" class="align-middle">
              <button
                [disabled]="mission.missions.address"
                class="btn btn-success"
                (click)="getRoom()"
              >
                {{ "join_room" | localise }}
              </button>
            </td>
            <td class="align-middle">
              {{ mission.missions.date }} : {{ mission.missions.time }}
            </td>
            <td *ngIf="!mission.is_interpretation" class="align-middle">
              {{ mission.professionnel.businessName }}
            </td>
            <td *ngIf="mission.is_interpretation" class="align-middle">
              {{ mission.particulier.email }}
            </td>
            <td class="align-middle">{{ mission.price }}</td>
            <td class="align-middle">
              {{ mission.state | prestationStateName }}
            </td>
            <td class="align-middle">
              {{ mission.missions.srcLang.iso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ mission.missions.destLang | iso639ToNative }}
            </td>
            <td class="align-middle">{{ mission.room }}</td>
            <td *ngIf="!mission.is_interpretation" class="align-middle">
              {{ mission.professionnel.businessName }}
            </td>
            <td *ngIf="mission.is_interpretation" class="align-middle">xxx</td>
            <td class="align-middle">
              {{ mission.missions.extHours }} {{ "hours" | localise }}
            </td>
            <td class="align-middle">{{ mission.missions.address }}</td>
            <!-- <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">{{ prestation.Data.srcLanguageIso639 | iso639ToNative }}</td>
            <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">{{ prestation.Data.srcCountryCode | country }}</td>
            <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">{{ prestation.Data.destLanguageIso639 | iso639ToNative }}</td>
            
            <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">{{ prestation.Data.state | prestationStateName }}</td>
            <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">{{ prestation.Data.price.traducteurHT }} €</td>
            <td  *ngIf="prestation.Data.srcCountryCode !=''" class="align-middle">
              <i class="fa fa-envelope text-info" aria-hidden="true"></i> x {{ getUnreadMessages(prestation) }}
            </td> -->
          </tr>
        </tbody>
      </table>
      <ngb-pagination
        *ngIf="!!missions"
        [collectionSize]="missions.length"
        [page]="page"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event)"
        aria-label="Default pagination"
      ></ngb-pagination>
    </div>

    <!-- <div class="col">
      <div class="card-group">
        <div class="card">

          <img class="card-img-top" src="{{subscriptionsList.images[0]}}">
          <div class="card-body">
            <h5 class="card-title">{{ subscriptionsList.name }}
            </h5>

            <p class="card-text"><small class="text-muted">
                {{'created_at' | localise }}{{ subscriptionsList.created * 1000 | date:'d, MMM, y, h:mm:ss a'  }}
              </small></p>
            <p class="card-text"><small class="text-muted">
                {{ 'updated_at' | localise}} {{ subscriptionsList.updated * 1000 | date:'d, MMM, y, h:mm:ss a'  }}
              </small></p>
             <p class="card-text"><small class="text-muted">
                {{ 'services_list_active' | localise }} {{ subscriptionsList.active  }}
              </small></p> 
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="space"></div>
  <!-- <button type="button" class="btn btn-style" (click)="deleteSubscription()">Modifier mon abonnement</button> -->
  <div class="row op">
    <!-- <button type="button" class="btn btn-style" (click)="deleteSubscription()">Modifier mon abonnement</button> -->
    <div class="space"></div>
    <div class="col">
      <div class="card-body">
        <!-- <img class="card-img-top" src="{{subscriptionsList.images[0]}}" alt="subscription image hiero traductor">  -->
        <ul class="list-group">
          <li class="list-group-item">
            <label>{{ "question_inter" | localise }}</label>
            <td class="align-middle">
              <form [formGroup]="formInter" (ngSubmit)="onSubmitForm()">
                <div class="form-group">
                  <select formControlName="interpreter" class="selectpicker">
                    <option value="">
                      {{ "select_choose" | localise }}
                    </option>
                    <option value="true">
                      {{ "prestation_document_confirm_oui" | localise }}
                    </option>
                    <option value="false">
                      {{ "prestation_document_confirm_non" | localise }}
                    </option>
                  </select>
                  <div class="form-group">
                    <label *ngIf="profile.interpreter"
                      >{{ "question_inter_price" | localise }}
                    </label>
                    <div *ngIf="profile.interpreter">
                      <input
                        type="number"
                        formControlName="interpretationPrice"
                        value=" {{ profile.interpretationPrice }}"
                      />
                    </div>
                    <div *ngIf="profile.interpreter">
                      <strong>
                        {{ "question_inter_1" | localise }}
                        {{ profile.interpretationPrice }} €/{{
                          "hours" | localise
                        }}
                      </strong>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-info">
                  {{ "agency_setup_save" | localise }}
                </button>
              </form>
            </td>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="space"></div>
    <button class="btn btn-primary hidden" (click)="showCalend(0)" id="showCal">
      {{ "calendar" | localise }}
    </button>
    <button class="btn btn-primary" (click)="showCalend(1)" id="noCal">
      {{ "calendar_hidden" | localise }}
    </button>
    <app-calendar id="calend"></app-calendar>
  </div>
</div>
