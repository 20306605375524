import {
  APP_DOMAIN,
  APP_SUBPATH,
  GOOGLE_GEOCODE_API_KEY,
  STRIPE_PUBLIC_KEY,
  TWILIO_API_SID,
} from "../../common/environment/config.devdeploy";
import {
  IConfig,
  LoggingLevel,
} from "../../common/src/interfaces/config/IConfig";

export const environment: IConfig = {
  firebase: {
    apiKey: "AIzaSyAEY7wVAYri0RDBOw8oKWjWPaPrrIPXtCg",
    authDomain: "hierodev.firebaseapp.com",
    databaseURL: "https://hierodev.firebaseio.com",
    projectId: "hierodev",
    storageBucket: "hierodev.appspot.com",
    messagingSenderId: "974891592058",
    appId: "1:974891592058:web:3b35d1fc53125ab0",
  },
  app: {
    origin: APP_DOMAIN,
    subpath: APP_SUBPATH,
  },
  appBundleId: "tech.kevinglass.hiero",
  appMinimumVersion: "0",
  production: false,
  logLevel: LoggingLevel.All,
  google_geocode_apikey: GOOGLE_GEOCODE_API_KEY,
  resetRedirectUrl: "https://translator-hierodev.web.app",
  public_key_stripe: STRIPE_PUBLIC_KEY,
  public_key_twilio: TWILIO_API_SID,
};
